<template>
  <img class="checkmark" src="../assets/checkmark.svg">
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({})
export default class Checkmark extends Vue {
}
</script>
