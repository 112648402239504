import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3a07d36"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "text",
    placeholder: _ctx.placeholder,
    value: _ctx.modelValue,
    class: _normalizeClass(["input", { disabled: _ctx.disabled }]),
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
    disabled: _ctx.disabled
  }, null, 42, _hoisted_1))
}