import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11301b3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notifications" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: _normalizeClass(["notification", [notification.style]]),
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(notification.text), 1),
        _createElementVNode("div", {
          class: "button",
          onClick: ($event: any) => (_ctx.dismiss(index))
        }, " X ", 8, _hoisted_3)
      ], 2))
    }), 128))
  ]))
}